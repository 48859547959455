/* eslint-disable */
import { sortBy } from "lodash"


export enum House {
    Brobnar = "Brobnar",
    Dis = "Dis",
    Ekwidon = "Ekwidon",
    Elders = "Elders",
    Geistoid = "Geistoid",
    IronyxRebels = "IronyxRebels",
    Keyraken = "Keyraken",
    Logos = "Logos",
    Mars = "Mars",
    Sanctum = "Sanctum",
    Saurian = "Saurian",
    Shadows = "Shadows",
    StarAlliance = "StarAlliance",
    Unfathomable = "Unfathomable",
    Untamed = "Untamed",
}

export class HouseUtils {
    static readonly values = [House.Brobnar, House.Dis, House.Ekwidon, House.Elders, House.Geistoid, House.IronyxRebels, House.Keyraken, House.Logos, House.Mars, House.Sanctum, House.Saurian, House.Shadows, House.StarAlliance, House.Unfathomable, House.Untamed]
    static valueOf = (value: string) => HouseUtils.values.find(enumValue => enumValue === value)
    static sort = (value: House[]) => sortBy(value, expansion => HouseUtils.values.indexOf(expansion))
}